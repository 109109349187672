import React, { useState } from 'react';
import { RiArrowUpSLine } from '@react-icons/all-files/ri/RiArrowUpSLine';

const BackToTop = ({ inactiveColor = '#83D4F1' }) => {
  const [iconColor, setIconColor] = useState(inactiveColor);
  return (
    <div className="sm:mr-16">
      <div
        onMouseEnter={() => setIconColor('#ffb81c')}
        onMouseLeave={() => setIconColor(inactiveColor)}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        className="flex items-center justify-center w-full px-10 pt-5 mt-2 ml-2 text-xs text-white capitalize cursor-pointer sm:pt-0 sm:justify-end"
      >
        <RiArrowUpSLine
          className="flex self-center text-3xl text-white border border-white rounded-full "
          style={{ color: iconColor }}
        />
        <div className="pl-2" style={{ color: iconColor }}>
          {' '}
          <span className="hover:text-yellow"> Back to top</span>
        </div>
      </div>
    </div>
  );
};

export default BackToTop;
