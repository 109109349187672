import React from 'react';
import Footer from '../Shared/Footer';
import NMDSINavbar from './NMDSINavbar';
import { Helmet } from 'react-helmet';

const NMDSILayout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <meta name="page" content="DSI" key="DSI"></meta>
      </Helmet>
      <NMDSINavbar />
      {children}
      <Footer />
    </div>
  );
};

export default NMDSILayout;
