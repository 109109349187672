import React from 'react';

import { BsChevronLeft } from '@react-icons/all-files/bs/BsChevronLeft';
import { BsChevronRight } from '@react-icons/all-files/bs/BsChevronRight';

const Pagination = ({ pageLimit, currentPage, setCurrentPage, numPages }) => {
  const goToNextPage = () => {
    setCurrentPage((page) => page + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((page) => page - 1);
  };

  const changePage = (event) => {
    const pageNumber = Number(event.target.value);
    const canChangePage = pageNumber - 1 <= numPages;
    if (canChangePage) {
      setCurrentPage(pageNumber - 1);
    } else {
      return null;
    }
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return Array.from({ length: numPages - start < pageLimit ? numPages - start : pageLimit }).map(
      (_, idx) => start + idx + 1,
    );
  };

  const hasNextPage = currentPage + 1 <= numPages;
  const hasPrevPage = currentPage - 1 >= 1;

  const disabledPrev = hasPrevPage ? '' : 'disabled-link';
  const disabledNext = hasNextPage ? '' : 'disabled-link';

  return (
    <div className="flex justify-center">
      <button onClick={goToPreviousPage} className={`${disabledPrev}`}>
        <BsChevronLeft className="flex self-center text-2xl text-blue" />
      </button>

      {getPaginationGroup().map((i, idx) => (
        <button
          className={`text-black px-3 text-xs ${currentPage === i ? 'activeItem' : null}`}
          key={idx}
          onClick={changePage}
          value={i + 1}
        >
          {i}
        </button>
      ))}

      <button onClick={goToNextPage} className={`${disabledNext}`}>
        <BsChevronRight className="flex self-center mr-4 text-2xl text-blue" />
      </button>
    </div>
  );
};

export default Pagination;
